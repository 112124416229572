<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/room" placeholder="请输入房间编号" class="main_left" />
  <div class="main_right">
    <vxe-form v-bind="searchHouseOptions" class="search" @submit="searchEvent" @reset="searchResetEvent">
      <template #buttons>
        <vxe-button status="primary" :label="item.value" :content="item.label" v-for="(item,index) in tabList" :key="index" @click="events(item)"></vxe-button>
      </template>
    </vxe-form>

    <vxe-modal ref="xModal" title="请选择房间" width="1000" height="600" show-zoom>
      <template v-slot>
        <VxeBasicTable ref="xGrid" service="/communitys/tBuildingRoom" :gridOptions="gridOptions" :searchOptions="searchOptions">
        </VxeBasicTable>
      </template>
    </vxe-modal>

    <div class="wrapper" v-loading="loading">
      <!-- 未交费账单 -->
      <NotPayTable ref="NotPayTable" :currNode="currNode"></NotPayTable>
      <!-- 历史账单 -->
      <OrderHistory ref="OrderHistory" :currNode="currNode"></OrderHistory>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions } from "vuex";
import NotPayTable from './NotPayTable'
import OrderHistory from './OrderHistory'
export default {
  components: {
    NotPayTable,
    OrderHistory
  },
  data() {
    return {
      loading: false,
      currNode: {},
      tabList: [
        { label: '历史账单', value: '1', name: "OrderHistory" },
      ],
      searchHouseOptions: {
        titleWidth: "100",
        items: [{
            title: '左侧',
            span: 12,
            children: [{
                field: "selectType",
                resetValue: "1",
                itemRender: {
                  name: "$select",
                  optionTypeCode: "searchTypes",
                  props: {
                    placeholder: "房间编号",
                  }
                },
              },
              {
                field: "name",
                visibleMethod({ data, property }) {
                  if (data.selectType == 1) {
                    return true
                  } else {
                    data[property] = "";
                    return false
                  }
                },
                itemRender: {
                  name: "$input",
                  props: {
                    maxlength: 24,
                    placeholder: "请输入房间编号"
                  },
                },
              },
              {
                field: "ownerName",
                visibleMethod({ data, property }) {
                  if (data.selectType == 2) {
                    return true
                  } else {
                    data[property] = "";
                    return false
                  }
                },
                itemRender: {
                  name: "$input",
                  props: {
                    maxlength: 24,
                    placeholder: this.$t("ownerNamePlaceholder")
                  },
                },
              },
              {
                field: "ownerLink",
                visibleMethod({ data, property }) {
                  if (data.selectType == 3) {
                    return true
                  } else {
                    data[property] = "";
                    return false
                  }
                },
                itemRender: {
                  name: "$input",
                  props: {
                    maxlength: 11,
                    placeholder: "请输入手机号"
                  },
                },
              },
              {
                align: "right",
                itemRender: {
                  name: '$buttons',
                  children: [{
                      props: {
                        type: 'submit',
                        content: '查询',
                        status: 'primary'
                      },
                    },
                    {
                      props: {
                        type: "reset",
                        content: "重置",
                      },
                    }
                  ]
                },
              },
            ]
          },
          {
            span: 12,
            align: "right",
            slots: { default: 'buttons' },
          },
        ],
        data: {
          selectType: "1",
        },
      },
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          enabled: false,
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            field: "name",
            title: "房屋编号",
            minWidth: 100,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入房屋编号",
              },
            },
          },
          {
            field: "ownerName",
            title: this.$t("currentOwner"),
            minWidth: 100,
            editRender: {
              name: "$input",
              props: {
                placeholder: this.$t("currentPlaceholder"),
              }
            },
          },
          {
            field: "ownerLink",
            title: "联系电话",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                placeholder: "请输入联系电话",
              }
            },
          },
          {
            field: "roomType",
            title: "房屋类型",
            minWidth: 100,
            editRender: {
              name: "$select",
              optionTypeCode: "houseType",
              props: {
                placeholder: "请选择房屋类型",
              },
            },
          },
          {
            field: "section",
            title: "房屋户型",
            minWidth: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "section",
              props: {
                placeholder: "请选择房屋户型",
              },
            },
          },
          {
            field: "roomBuiltUpArea",
            title: "建筑面积",
            minWidth: 100,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入建筑面积",
              },
            },
          },
          {
            field: "feeCoefficient",
            title: "算费系数",
            minWidth: 80,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                maxlength: 24,
                placeholder: "请输入算费系数",
              }
            },
          },
          {
            field: "status",
            title: "房屋状态",
            width: 100,
            editRender: {
              name: "$select",
              optionTypeCode: "houseStatus",
              props: {
                placeholder: "请选择房屋状态",
              }
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 100,
            cellRender: {
              name: "$button",
              props: {
                content: "选择",
                type: "text",
                status: "primary",
              },
              events: {
                click: ({ row, column }) => {
                  this.currNode = row;
                  this.$refs.xModal.close();
                }
              }
            },
          },
        ],
        editConfig: {
          enabled: false
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "selectType",
            resetValue: "1",
            itemRender: {
              name: "$select",
              optionTypeCode: "searchTypes",
              props: {
                placeholder: "房间编号",
              },
            },
          },
          {
            field: "name",
            visibleMethod({ data, property }) {
              if (data.selectType == 1) {
                return true
              } else {
                data[property] = "";
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入房间编号"
              },
            },
          },
          {
            field: "ownerName",
            visibleMethod({ data, property }) {
              if (data.selectType == 2) {
                return true
              } else {
                data[property] = "";
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: this.$t("ownerNamePlaceholder")
              },
            },
          },
          {
            field: "ownerLink",
            visibleMethod({ data, property }) {
              if (data.selectType == 3) {
                return true
              } else {
                data[property] = "";
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入手机号"
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          selectType: "1",
        },
      },
    }
  },
  created() {
    this.getSysDictEvent();
  },
  methods: {
    ...mapActions(['getSysDictService']),
    handleNodeClick(treeData, node) {
      let { data } = this.searchOptions
      data["parentType"] = node.type;
      data["parentId"] = node.id;
      if (node.type == "1004") {
        this.currNode = node;
      } else {
        this.currNode = {};
      }
    },

    // 获取数据字典
    getSysDictEvent() {
      this.getSysDictService({
          params: ["searchTypes"]
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.searchHouseOptions.items = [...this.$Tools.buildDataByDicts(this.searchHouseOptions.items, data, { value: "code", label: "name" })];
          }
        });
    },

    searchEvent({ data }) {
      if (data.selectType == 1 && !data.name) {
        this.$message.error("请输入房间编号");
      } else if (data.selectType == 2 && !data.ownerName) {
        this.$message.error(this.$t("ownerNamePlaceholder"));
      } else if (data.selectType == 3 && !data.ownerLink) {
        this.$message.error("请输入手机号");
      } else {
        this.searchOptions.data["selectType"] = data.selectType;
        if (data.selectType == 1) {
          this.searchOptions.data["name"] = data.name;
        } else if (data.selectType == 2) {
          this.searchOptions.data["ownerName"] = data.ownerName;
        } else if (data.selectType == 3) {
          this.searchOptions.data["ownerLink"] = data.ownerLink;
        }
        this.$refs.xModal.open();
      }
    },
    searchResetEvent() {
      this.searchHouseOptions.data = {
        selectType: "1",
        name: "",
        ownerName: "",
        ownerLink: "",
      }
      this.currNode = {};
    },

    events(item) {
      if (this.currNode.type == "1004") {
        this.$refs[item.name].init(item, this.currNode);
      } else {
        this.$message.error("请选择房间");
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.main {
  height: 100%;
  // padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
</style>
