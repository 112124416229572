<template>
<div class="main">
  <VxeTreeTable ref="xGrid" service="/fee/fPayFeeDetail" :gridOptions="gridOptions" :searchOptions="searchOptions" :defaultTrigger="false">
  </VxeTreeTable>
  <!-- 新增临时收费 -->
  <TemporaryFee ref="TemporaryFee" />
  <!-- 创建费用 -->
  <CycleCost ref="CycleCost" />
  <!-- 优惠折扣 -->
  <Discount ref="Discount" />
  <!-- 收款功能 -->
  <Collection ref="Collection" />
  <!-- 打印功能 -->
  <PrintHtml ref="PrintHtml" />
</div>
</template>

<script>
import { mapActions } from "vuex";
// 临时费用
import TemporaryFee from "./components/TemporaryFee"
import CycleCost from "./components/CycleCost"
import Discount from "./components/Discount"
import Collection from "./components/Collection"
import PrintHtml from '@/components/Base/printHtml'
export default {
  components: {
    TemporaryFee,
    CycleCost,
    Discount,
    Collection,
    PrintHtml
  },
  props: ["currNode"],
  data() {
    return {
      roomInfo: {},
      gridOptions: {
        rowId: 'id',
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [
            { code: "setAllTreeExpand", name: "全部展开", status: "primary" },
            { code: "clearTreeExpand", name: "全部收起", status: "danger" },
            {
              code: 'buyWater',
              name: '购水',
              status: 'primary',
              events: {
                click: (data) => {
                  this.toolbarCustomEvent(data);
                }
              }
            },
            {
              code: 'buyPower',
              name: '购电',
              status: 'success',
              events: {
                click: (data) => {
                  this.toolbarCustomEvent(data);
                }
              }
            },
            {
              code: 'cycleCost',
              name: '创建费用',
              status: 'primary',
              events: {
                click: (data) => {
                  this.toolbarCustomEvent(data);
                }
              }
            },
            {
              code: 'tempCost',
              name: '临时费用',
              status: 'warning',
              events: {
                click: (data) => {
                  this.toolbarCustomEvent(data);
                }
              }
            },
            {
              code: 'discount',
              name: '优惠折扣',
              status: 'success',
              events: {
                click: (data) => {
                  this.toolbarCustomEvent(data)
                }
              }
            },
            {
              code: 'collection',
              name: '收款',
              status: 'danger',
              events: {
                click: (data) => {
                  this.toolbarCustomEvent(data)
                }
              }
            },
            // { code: 'myInsert', name: '设置' },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        columns: [{
            title: "基本信息",
            children: [{
                type: "seq",
                width: 60
              },
              {
                field: "feeMonth",
                type: "checkbox",
                width: 140,
                title: "计费期",
                treeNode: true,
                align: "left",
                headerAlign: "center",
                footerAlign: "center"
              },
              {
                field: "configName",
                title: "项目名称",
                minWidth: 160,
                formatter: ({ cellValue, row, column }) => {
                  if (!cellValue) {
                    return "/"
                  } else {
                    return cellValue
                  }
                },
                editRender: {
                  name: "$select",
                  optionTypeCode: "costType",
                  props: {
                    placeholder: "请选择收费项目"
                  }
                }
              },
            ]
          },
          {
            title: "费用信息",
            children: [{
                field: "squarePrice",
                title: "单价",
                minWidth: 120,
                formatter: ({ cellValue, row, column }) => {
                  if (!cellValue) {
                    return "0.00"
                  } else {
                    return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
                  }
                },
                editRender: {
                  name: "$input",
                  props: {
                    placeholder: "请选择收费项目"
                  }
                }
              },
              {
                field: "usageAmount",
                title: "实用量",
                minWidth: 120,
                formatter: ({ cellValue, row, column }) => {
                  if (!cellValue) {
                    return "0.00"
                  } else {
                    return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
                  }
                },
                editRender: {
                  name: "$input",
                  props: {
                    placeholder: "请选择收费项目"
                  }
                }
              },
              {
                field: "feeCoefficient",
                title: "系数",
                minWidth: 60,
                formatter: ({ cellValue, row, column }) => {
                  if (!cellValue) {
                    return "0.00"
                  } else {
                    return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
                  }
                },
                editRender: {
                  name: "$input",
                  props: {
                    placeholder: "请输入系数"
                  }
                }
              },
              {
                field: "receivableAmount",
                title: "应收金额",
                minWidth: 100,
                editRender: {
                  name: "$input",
                  props: {
                    type: "float",
                    min: 0,
                    max: 99999,
                    digits: 3,
                    maxlength: 24,
                    placeholder: "请输入应收金额"
                  }
                }
              },
              {
                field: "discountDetail",
                title: "优惠金额",
                formatter: ({ cellValue }) => {
                  if (!cellValue) {
                    return "0.000"
                  } else {
                    return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
                  }
                },
                minWidth: 100,
                editRender: {
                  name: "$input",
                  props: {
                    type: "float",
                    min: 0,
                    max: 99999,
                    digits: 3,
                    maxlength: 24,
                    placeholder: "请输入优惠金额"
                  }
                }
              },
              {
                field: "receivedAmount",
                title: "实收金额",
                minWidth: 100,
                editRender: {
                  name: "$input",
                  props: {
                    type: "float",
                    min: 0,
                    max: 99999,
                    digits: 3,
                    maxlength: 24,
                    placeholder: "请输入实收金额"
                  }
                }
              },
              // {
              //   field: "startTime",
              //   title: "开始时间",
              //   minWidth: 100,
              //   editRender: {
              //     name: "$input",
              //     props: {
              //       type: "date",
              //       valueFormat: "yyyy-MM-dd HH:mm:ss",
              //       placeholder: "请选择费用开始时间"
              //     }
              //   }
              // },
              // {
              //   field: "endTime",
              //   title: "结束时间",
              //   minWidth: 100,
              //   editRender: {
              //     name: "$input",
              //     props: {
              //       type: "date",
              //       valueFormat: "yyyy-MM-dd HH:mm:ss",
              //       placeholder: "请选择费用结束时间"
              //     }
              //   }
              // },
            ]
          },
          // {
          //   title: "折扣信息",
          //   children: [{
          //       field: "discountName",
          //       title: "打折名称",
          //       minWidth: 100,
          //       editRender: {
          //         name: "$input",
          //         props: {
          //           maxlength: 24,
          //           placeholder: "请输入打折名称"
          //         }
          //       }
          //     },
          //     {
          //       field: "discountPrice",
          //       title: "折扣金额",
          //       minWidth: 100,
          //       formatter: ({ cellValue, row, column }) => {
          //         if (!cellValue) {
          //           return "/"
          //         } else {
          //           return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
          //         }
          //       },
          //       editRender: {
          //         name: "$input",
          //         props: {
          //           type: "float",
          //           min: 0,
          //           max: 99999,
          //           maxlength: 24,
          //           placeholder: "请输入折扣金额"
          //         }
          //       }
          //     },
          //     {
          //       field: "discountDetail",
          //       title: "折扣明细",
          //       minWidth: 160,
          //       editRender: {
          //         name: "$input",
          //         props: {
          //           maxlength: 24,
          //           placeholder: "请输入折扣明细"
          //         }
          //       }
          //     },
          //   ]
          // },
          // {
          //   title: "违约金信息",
          //   children: [{
          //       field: "breachPrice",
          //       title: "违约金",
          //       minWidth: 100,
          //       formatter: ({ cellValue, row, column }) => {
          //         if (!cellValue) {
          //           return "/"
          //         } else {
          //           return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
          //         }
          //       },
          //       editRender: {
          //         name: "$input",
          //         props: {
          //           type: "float",
          //           min: 0,
          //           max: 99999,
          //           maxlength: 24,
          //           placeholder: "请输入违约金"
          //         }
          //       }
          //     },
          //     {
          //       field: "breachTime",
          //       title: "违约起始时间",
          //       minWidth: 120,
          //       editRender: {
          //         name: "$input",
          //         props: {
          //           type: "date",
          //           valueFormat: "yyyy-MM-dd HH:mm:ss",
          //           placeholder: "请选择违约起始时间"
          //         }
          //       }
          //     },
          //   ]
          // },
          // {
          //   fixed: "right",
          //   title: "操作",
          //   width: 120,
          //   editRender: {
          //     name: "$VxeGridButtons",
          //     lists: [{
          //       status: "danger",
          //       label: "删除",
          //       eventName: "removeEvent"
          //     }]
          //   }
          // }
        ],
        treeConfig: {
          children: 'children',
          rowField: 'id',
          reserve: true,
        },
        checkboxConfig: {
          showHeader: true,
          labelField: 'feeMonth',
          trigger: "row"
        },
        formConfig: {
          // 新增功能字段配置
          size: "small",
          width: 800,
          titleWidth: 100,
          items: [{
              field: "name",
              title: "房间编号",
              span: 6,
              itemRender: {
                name: "$input",
                props: {
                  disabled: true,
                  placeholder: "房间编号",
                },
              },
            },
            {
              field: "ownerName",
              title: this.$t("ownerName"),
              span: 6,
              itemRender: {
                name: "$select",
                props: {
                  disabled: true,
                  placeholder: this.$t("ownerName"),
                },
              },
            },
            {
              field: "ownerLink",
              title: "联系电话",
              span: 6,
              itemRender: {
                name: "$select",
                props: {
                  disabled: true,
                  placeholder: "联系电话",
                },
              },
            },
            {
              field: "roomType",
              title: "房屋类型",
              span: 6,
              itemRender: {
                name: "$select",
                optionTypeCode: "houseType",
                props: {
                  disabled: true,
                  placeholder: "房屋类型",
                },
              },
            },
            {
              field: "roomBuiltUpArea",
              title: "建筑面积",
              span: 6,
              itemRender: {
                name: "$input",
                props: {
                  disabled: true,
                  type: "float",
                  min: 0,
                  max: 99999,

                  maxlength: 24,
                  placeholder: "建筑面积",
                },
              },
            },
            {
              field: "feeCoefficient",
              title: "算费系数",
              span: 6,
              itemRender: {
                name: "$input",
                props: {
                  disabled: true,
                  type: "float",
                  min: 0,
                  max: 1,

                  placeholder: "算费系数",
                },
              },
            },
            {
              field: "status",
              title: "房屋状态",
              span: 6,
              itemRender: {
                name: "$select",
                optionTypeCode: "houseStatus",
                props: {
                  disabled: true,
                  placeholder: "房屋状态",
                },
              },
            },
          ],
          // 新增功能字段初始化
          data: {},
        },
        editConfig: {
          enabled: false
        },
        cellClassName: ({ row, column }) => {
          if (column.property === 'receivableAmount') {
            return 'blue'
          }
          if (column.property === 'discountDetail') {
            return 'red'
          }
          if (column.property === 'receivedAmount') {
            return 'green'
          }
          return null
        },
        footerCellClassName: ({ row, column }) => {
          if (column.property === 'receivableAmount') {
            return 'blue'
          }
          if (column.property === 'discountDetail') {
            return 'red'
          }
          if (column.property === 'receivedAmount') {
            return 'green'
          }
          return null
        },
        size: "medium",
        showFooter: true,
        footerMethod: ({ columns, data }) => {
          let arrs = ["receivableAmount", "discountDetail", "receivedAmount"];
          return [
            columns.map((column, columnIndex) => {
              if (columnIndex === 0) {
                return '合计'
              } else if (arrs.includes(column.property)) {
                return this.sumNum(data, column.property)
              } else {
                return '/'
              }
            }),
          ]
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [],
        data: {
          parentId: "",
          status: 0
        },
      },
    };
  },
  watch: {
    currNode(val) {
      if (!val) {
        this.$refs.xGrid.tableData = [];
      }
      if (val && val.type == "1004") {
        this.loading = true;
        this.searchOptions.data['parentId'] = val.id;
        this.queryEvent(val);
      }
    },
  },

  methods: {
    ...mapActions(['findByEventService', 'queryEventService', 'submitEventService']),

    // 查询房屋信息
    queryEvent(row) {
      this.loading = true;
      this.queryEventService({
        service: '/communitys/tBuildingRoom',
        params: row,
        dictTypeCodes: ["houseType", "section", "toward", "houseStatus"]
      }).then(({ code, data, dicts }) => {
        if (code == 200) {
          this.roomInfo = data;
          let { formConfig } = this.gridOptions
          formConfig.data = data;
          formConfig.items = [...this.$Tools.buildDataByDicts(formConfig.items, dicts, { value: "code", label: "name" })];
          this.$refs.xGrid.getLists(row);
        }
      });
    },

    // 动态计算表尾的合计
    sumNum(list, field) {
      let count = 0;
      list.forEach(item => {
        count = this.$XEUtils.add(count, item[field]) // 30 Number(item[field])
      })
      return this.$XEUtils.commafy(count, { digits: 2 })
    },

    calcEvent(price, count) {
      return this.$XEUtils.commafy(this.$XEUtils.multiply(price, count), { digits: 2 });
    },

    toolbarCustomEvent({ name, code }) {
      let { type } = this.roomInfo;
      let selectRecords = [];
      let records = this.$refs.xGrid.$refs.xGrid.getCheckboxRecords();
      records.map(item => {
        if (!item.children) {
          selectRecords.push(item);
        }
      });

      if (!type || type != "1004") {
        this.$message.error('请选择房屋')
        return;
      }
      if (code == "cycleCost") {
        this.$refs.CycleCost.init(name, code, this.roomInfo)
      } else if (code == "discount") {
        if (!selectRecords.length) {
          this.$message.error("请选择要缴费的信息");
        } else {
          this.$refs.Discount.init(name, code, this.roomInfo, JSON.stringify(selectRecords))
        }
      } else if (code == "collection") {
        if (!selectRecords.length) {
          this.$message.error("请选择要缴费的信息");
        } else {
          this.$refs.Collection.init(name, code, this.roomInfo, selectRecords)
        }
      } else {
        this.$refs.TemporaryFee.init(name, code, this.roomInfo)
      }
    },

    // 打印
    getPrintDataEvent({ orderId }) {
      this.$Axios.get(`/order/order/printOrder/${orderId}`).then(({ code, data }) => {
        if (code == 200) {
          this.$refs.PrintHtml.printEvent(data);
        }
      })
    },
  },
};
</script>

<style lang="scss"></style>
