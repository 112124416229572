<template>
<div>
  <vxe-modal ref="xModal" title="收款确认" width="1200" show-zoom>
    <template v-slot>
      <div class="wrapper">
        <div class="collection">
          <vxe-grid ref="xGrid" height="300" :loading="loading" v-bind="gridOptions" :data="tableData"></vxe-grid>
        </div>
        <vxe-form ref="xForm" :title-width="formOptions.titleWidth" :items="formOptions.items" :data="formData" :rules="formOptions.rules" @submit="submitEvent" @reset="closeModal">
          <!-- 扫码支付特殊处理 -->
          <template #payCode="{ data }">
            <vxe-input ref="payCode" v-model="data.payCode" placeholder="扫码支付" @focus="focusEvent"></vxe-input>
          </template>
        </vxe-form>
      </div>
    </template>
  </vxe-modal>

  <vxe-modal ref="qrcode" :title="qrcodeTitle" width="400" height="500" resize remember @close="closeQrcodeModal">
    <template #default>
      <img :src="qrcodeImg" class="qrcode">
    </template>
  </vxe-modal>

  <div class="scanPay" v-if="showScanPay" @click="payCodeEvent">
    <span>
      <i class="vxe-icon--close" @click="closeScanPay"></i>
      <img src="../../../../../assets/pay/saomazhifu.gif" />
      <h2>请扫描付款码</h2>
    </span>
  </div>

  <div class="orderLoading" v-if="orderStatus">
    <dl>
      <dt v-loading="true" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0)"></dt>
      <dd>正在查询订单支付状态，请勿刷新页面...</dd>
    </dl>
  </div>
</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      qrcodeTitle: "", // 2,weixin 3zhifubao 4,yinlian
      qrcodeImg: '',
      tableData: [],
      gridOptions: {
        // 列表字段配置
        // 列表功能按钮组
        toolbarConfig: {
          enabled: false,
        },
        columns: [{
            field: "feeName",
            minWidth: 120,
            title: "项目名称",
          },
          {
            field: "feePeriod",
            minWidth: 150,
            title: "费用期间",
          },
          {
            field: "coefficient",
            title: "系数",
            minWidth: 60,
            editRender: {
              name: "$input",
            }
          },
          {
            field: "quantity",
            title: "实用量",
            minWidth: 80,
            editRender: {
              name: "$input",
            }
          },
          {
            field: "unitPrice",
            title: "单价",
            minWidth: 80,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入应收金额"
              }
            }
          },
          {
            field: "receivableAmount",
            title: "应收金额",
            minWidth: 80,
            formatter: ({ cellValue }) => {
              if (!cellValue) {
                return "0.000"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 3 })
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                digits: 3,
                maxlength: 24,
                placeholder: "请输入应收金额"
              }
            }
          },
          {
            field: "amountReceived",
            title: "已收金额",
            minWidth: 80,
            formatter: ({ cellValue }) => {
              if (!cellValue) {
                return "0.000"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 3 })
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                digits: 3,
                maxlength: 24,
                placeholder: "请输入已收金额"
              }
            }
          },
          {
            field: "discountDetail",
            title: "优惠金额",
            formatter: ({ cellValue }) => {
              if (!cellValue) {
                return "0.000"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 3 })
              }
            },
            minWidth: 100,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                digits: 3,
                maxlength: 24,
                placeholder: "请输入优惠金额"
              }
            }
          },
          {
            field: "lateFee",
            title: "违约金",
            minWidth: 80,
            formatter: ({ cellValue }) => {
              if (!cellValue) {
                return "0.000"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 3 })
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                digits: 3,
                maxlength: 24,
                placeholder: "请输入违约金"
              }
            }
          },
          {
            field: "predepositOffset",
            title: "预存冲抵",
            minWidth: 80,
            formatter: ({ cellValue }) => {
              if (!cellValue) {
                return "0.000"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 3 })
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                digits: 3,
                maxlength: 24,
                placeholder: "请输入预存冲抵"
              }
            }
          },
          {
            field: "receivedAmount",
            title: "本次交款",
            minWidth: 80,
            formatter: ({ cellValue }) => {
              if (!cellValue) {
                return "0.000"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 3 })
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                digits: 3,
                maxlength: 24,
                placeholder: "请输入实收金额"
              }
            }
          },
          {
            field: "arrears",
            title: "欠款",
            minWidth: 80,
            formatter: ({ cellValue }) => {
              if (!cellValue) {
                return "0.000"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 3 })
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                digits: 3,
                maxlength: 24,
                placeholder: "请输入实收金额"
              }
            }
          },
        ],
        cellClassName: ({ row, column }) => {
          if (column.property === 'receivableAmount') {
            return 'blue'
          }
          if (column.property === 'discountDetail' || column.property === 'lateFee' || column.property === 'arrears') {
            return 'red'
          }
          if (column.property === 'receivedAmount') {
            return 'green'
          }
          return null
        },
        treeConfig: {
          children: 'children',
          expandAll: true,
        },
        editConfig: {
          enabled: false
        },
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "receivableAmount",
            title: "应收合计",
            span: 8,
            itemRender: {
              name: "$input",
              props: {
                disabled: true,
                type: "float",
                min: 0,
                max: 99999,
                digits: 3,
                maxlength: 24,
                placeholder: "请输入应收合计",
              },
            },
          },
          {
            field: "discountAmount",
            title: "优惠合计",
            span: 8,
            itemRender: {
              name: "$input",
              props: {
                disabled: true,
                type: "float",
                min: 0,
                max: 99999,
                digits: 3,
                maxlength: 24,
                placeholder: "请输入优惠合计",
              },
            },
          },
          {
            field: "receivedAmount",
            title: "实收合计",
            span: 8,
            itemRender: {
              name: "$input",
              props: {
                disabled: true,
                type: "float",
                min: 0,
                max: 99999,
                digits: 3,
                maxlength: 24,
                placeholder: "请输入实收合计",
              },
            },
          },
          {
            field: "totalAmount",
            title: "本次缴费",
            span: 8,
            itemRender: {
              name: "$input",
              props: {
                disabled: true,
                type: "float",
                min: 0,
                max: 99999,
                digits: 3,
                maxlength: 24,
                placeholder: "请输入本次缴费",
              },
            },
          },
          {
            field: "carryWay",
            title: "进位方式",
            span: 8,
            itemRender: {
              name: "$select",
              optionTypeCode: "carryWay",
              props: {
                // disabled: true,
                placeholder: "请选择进位方式",
              },
              events: {
                change: ({ data, property }) => {
                  if (data[property] == 1) {
                    data["totalAmount"] = this.$XEUtils.toFixed(data["receivedAmount"], 2)
                  } else if (data[property] == 2) {
                    data["totalAmount"] = this.$XEUtils.toFixed(data["receivedAmount"], 1)
                  } else if (data[property] == 3) {
                    data["totalAmount"] = this.$XEUtils.round(data["receivedAmount"], 0);
                    // data["totalAmount"] = this.$XEUtils.round(this.$XEUtils.toFixed(data["receivableAmount"], 1), 0);
                  } else if (data[property] == 4) {
                    data["totalAmount"] = this.$XEUtils.toInteger(data["receivedAmount"], 0)
                  }
                }
              }
            },
          },
          {
            field: "payType",
            title: "支付方式",
            span: 8,
            itemRender: {
              name: "$select",
              optionTypeCode: "payType",
              props: {
                placeholder: "请选择支付方式",
              },
              events: {
                change: ({ data, property }) => {
                  if (data[property] == 50) {
                    this.payCodeEvent();
                  }
                }
              }
            },
          },
          {
            field: 'payCode',
            span: 0,
            className: "payCode",
            slots: { default: 'payCode' },
          },
          {
            field: 'remark',
            title: '收款摘要',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "50",
                resize: "none",
                placeholder: '请输入收款摘要'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "确定",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          configId: [{ required: true, message: "请选择收费项" }],
          amount: [{ required: true, message: "请输入购买金额" }],
          payType: [{ required: true, message: "请选择支付方式" }],
          carryWay: [{ required: true, message: "请选择进位方式" }],
        },
        // 新增功能字段初始化
        data: {
          communityId: "",
          feeIdList: [],
          receivableAmount: 0,
          amountReceived: 0,
          lackTotalAmount: 0,
          discountAmount: 0,
          preferentialAmount: 0,
          breaksAmount: 0,
          offsetAmount: 0,
          totalAmount: 0,
          certificateNum: "",
          payType: "",
          carryWay: "",
          payCode: "" // 付款码
        },
        timer: null,
      },
      formData: {},
      showScanPay: false,
      orderStatus: false,
    };
  },
  methods: {
    ...mapActions(['getSysDictService', 'submitEventService', 'getEventService']),

    // 初始化方法
    init(name, code, room, selectRecords) {
      let { data } = this.formOptions;
      data["communityId"] = room.communityId;
      this.getSummaryFeeDetail(room, selectRecords);
      this.$refs.xModal.open();
    },

    getSummaryFeeDetail(room, selectRecords) {
      this.loading = true;
      let idList = [];
      selectRecords.forEach(item => {
        idList.push(item.id)
      });
      this.$Axios.post(`/order/order/getSummaryFeeDetail`, idList).then(({ code, data }) => {
        if (code == 200) {
          this.getSysDictEvent();
          this.tableData = data;
          this.costCalcEvent(room, selectRecords);
        }
        this.loading = false;
      });
    },

    // 获取数据字典
    getSysDictEvent() {
      this.getSysDictService({
          params: ["costType", "payType", "carryWay"]
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.gridOptions.columns = [...this.$Tools.buildDataByDicts(this.gridOptions.columns, data, { value: "code", label: "name" })];
            this.formOptions.items = [...this.$Tools.buildDataByDicts(this.formOptions.items, data, { value: "code", label: "name" })];
          }
        });
    },

    // 金额等字段的计算及初始化数据
    costCalcEvent(room, selectRecords) {
      let { data } = this.formOptions;
      data["buildingId"] = room.floorId;
      data["buildingCode"] = room.buildingCode;
      data["unitId"] = room.unitId;
      data["unitCode"] = room.unitCode;
      data["roomId"] = room.id;
      data["roomName"] = room.name;
      data["ownerId"] = room.ownerId;
      data["feeIdList"] = [];
      
      // 应收合计
      data["receivableAmount"] = 0.000;
      data["lackTotalAmount"] = 0.000;
      // 优惠合计
      data["discountAmount"] = 0.000;
      // 本次缴费
      data["totalAmount"] = 0.000;
      selectRecords.forEach(item => {
        data["feeIdList"].push(item.id);
        data["discountAmount"] = this.$XEUtils.toFixed(this.$XEUtils.add(data["discountAmount"], item["discountDetail"]), 3);
        data["receivableAmount"] = this.$XEUtils.toFixed(this.$XEUtils.add(data["receivableAmount"], item["receivableAmount"]), 3);
        data["receivedAmount"] = data["totalAmount"] = this.$XEUtils.toFixed(this.$XEUtils.add(data["totalAmount"], item["receivedAmount"]), 3);
      });
      this.formData = { ...data };
    },

    // 扫描支付事件
    payCodeEvent() {
      this.showScanPay = true;
      this.$refs.payCode.focus();
    },

    closeScanPay(event) {
      event.stopPropagation()
      this.showScanPay = false;
    },

    focusEvent({ value, $event }) {
      $event.currentTarget.select();
    },

    // 保存提交事件
    submitEvent({ data }) {
      if (data.payType == 50 && !data.payCode) {
        this.payCodeEvent();
        return;
      }
      this.$refs["xForm"].validate((valid) => {
        if (!valid) {
          // 扫码支付
          if (data.payType == 50) {
            this.showScanPay = false;
            this.submitData(data);
          } else {
            this.$confirm("请确认是否缴费", this.$t("confirmTitle")).then((type) => {
              if (type === "confirm") {
                this.submitData(data)
              }
            });
          }
        }
      });
    },

    submitData(params) {
      this.orderStatus = true;
      this.submitEventService({
        service: `/order/order`,
        params,
      }).then(({ code, data, message }) => {
        if (code == 200) {
          // 微信和支付宝扫码支付
          if (data && data.img) {
            this.payEvent(data)
          } else if (params.payType == 50) {
            this.getOrderInfo(data);
          } else {
            this.closeModal();
            this.getOrderInfo(data);
          }
        }
        if (code == 201) {
          this.$message.error(message)
        }
        this.resetEvent();
      }).catch((err) => {
        this.resetEvent();
      });
    },

    // 立即支付
    payEvent(params) {
      this.$Axios.post("/order/order/repayments", { id: params.id }).then(({ code, data }) => {
        if (code == 200) {
          let payType = {
            '2': '请用微信扫码',
            '3': '请用支付宝扫码',
            '4': '银联支付'
          }
          this.qrcodeTitle = payType[this.formOptions.data.payType]
          this.qrcodeImg = 'data:image/jpg;base64,' + data.img;
          // this.closeModal();
          this.$refs.qrcode.open();
          // 定时器查询支付状态
          this.timer = setInterval(() => {
            this.getOrderInfo(params);
          }, 3000)
        }
      })
    },

    getOrderInfo(params) {
      if (this.formData.payType == 50) {
        this.orderStatus = true;
      }
      this.getEventService({
        service: `/order/order/${params.id}`,
        params: {}
      }).then(({ code, data }) => {
        if (code == 200) {
          if (data.status == '30') {
            this.orderStatus = false;
            this.closeModal();
            this.closeQrcodeModal();
            this.$confirm("支付成功,是否打印收款凭证", this.$t("confirmTitle")).then((type) => {
              if (type === "confirm") {
                this.$parent.getPrintDataEvent(data);
              }
            })
          }
          if (data.status == '101320949703580149370') {
            this.orderStatus = false;
            this.$message.error('用户取消支付')
            this.closeModal();
            this.closeQrcodeModal();
          }
        }
      })
    },

    resetEvent() {
      this.formData.payCode = "";
      this.orderStatus = false;
      this.loading = false;
    },

    closeModal() {
      this.$refs.xModal.close();
    },

    closeQrcodeModal() {
      this.$parent.$refs.xGrid.getLists();
      this.$refs.qrcode.close()
      clearInterval(this.timer)
    },
  },
};
</script>

<style lang="scss">
.collection {
  padding: 10px;
}

.payCode {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -9999;
}

.qrcode {
  width: 400px;
  height: 400px;
  margin: 20px auto;
}

.scanPay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($color: #000000, $alpha: 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;

  span {
    width: 500px;
    padding: 50px;
    background: #f7f7f7;
    position: relative;

    i {
      display: block;
      font-size: 50px;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    h2 {
      height: 50px;
      line-height: 50px;
      text-align: center;
    }
  }
}

.orderLoading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($color: #000000, $alpha: 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;

  dl {
    display: block;
    width: 500px;
    padding: 30px;
    background: #f7f7f7;
    border-radius: 5px;
    position: relative;

    dt {
      height: 80px;

      .el-icon-loading {
        font-size: 50px !important;
      }
    }

    dd {
      font-size: 24px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      z-index: 9999;
    }
  }
}
</style>
