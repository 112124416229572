<template>
<div>
  <vxe-modal ref="xModal" title="优惠金额" width="1200" height="600" show-zoom>
    <template v-slot>
      <div class="wrapper">
        <vxe-grid ref="xGrid" height="auto" :loading="loading" v-bind="gridOptions" :data="tableData" @toolbar-button-click="toolbarCustomEvent"></vxe-grid>
      </div>
    </template>
  </vxe-modal>
</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      tableData: [],
      gridOptions: {
        // 列表字段配置
        toolbarConfig: {
          buttons: [{
            code: "saveEvent",
            name: "保存",
            status: "primary",
            events: {
              click: () => {
                this.saveEvent();
              },
            },
          }, ],
        },
        columns: [{
            title: "基本信息",
            children: [{
                type: "seq",
                width: 60,
              },
              {
                field: "feeMonth",
                width: 100,
                title: "计费期",
              },
              {
                field: "configName",
                title: "项目名称",
                minWidth: 100,
                formatter: ({ cellValue, row, column }) => {
                  if (!cellValue) {
                    return "/";
                  } else {
                    return cellValue;
                  }
                },
                editRender: {
                  name: "$select",
                  optionTypeCode: "costType",
                  props: {
                    placeholder: "请选择收费项目",
                  },
                },
              },
            ],
          },
          {
            title: "费用信息",
            children: [{
                field: "squarePrice",
                title: "单价",
                minWidth: 100,
                formatter: ({ cellValue, row, column }) => {
                  if (!cellValue) {
                    return "/";
                  } else {
                    return this.$XEUtils.commafy(Number(cellValue), {
                      digits: 2,
                    });
                  }
                },
                editRender: {
                  name: "$input",
                  props: {
                    placeholder: "请选择收费项目",
                  },
                },
              },
              {
                field: "usageAmount",
                title: "实用量",
                minWidth: 100,
                formatter: ({ cellValue, row, column }) => {
                  if (!cellValue) {
                    return "/";
                  } else {
                    return this.$XEUtils.commafy(Number(cellValue), {
                      digits: 2,
                    });
                  }
                },
                editRender: {
                  name: "$input",
                  props: {
                    placeholder: "请选择收费项目",
                  },
                },
              },
              {
                field: "feeCoefficient",
                title: "系数",
                minWidth: 60,
                formatter: ({ cellValue, row, column }) => {
                  if (!cellValue) {
                    return "/";
                  } else {
                    return this.$XEUtils.commafy(Number(cellValue), {
                      digits: 2,
                    });
                  }
                },
                editRender: {
                  name: "$input",
                  props: {
                    placeholder: "请输入系数",
                  },
                },
              },
              {
                field: "receivableAmount",
                title: "应收金额",
                minWidth: 100,
                editRender: {
                  name: "$input",
                  props: {
                    type: "float",
                    min: 0,
                    max: 99999,
                    digits: 2,
                    maxlength: 24,
                    placeholder: "请输入应收金额",
                  },
                },
              },
              {
                field: "discountDetail",
                title: "优惠金额",
                minWidth: 160,
                cellRender: {
                  name: "$input",
                  props: {
                    type: "float",
                    digits: 2,
                    placeholder: "请输入优惠金额",
                  },
                  events: {
                    input: ({ row, column }) => {
                      if (row[column["property"]] > row["receivableAmount"]) {
                        this.$message.error("优惠金额不能大于应收金额，请重新填写");
                      } else {
                        row["receivedAmount"] = Number(row["receivableAmount"]) - Number(row[column["property"]]);
                      }
                    },
                    blur: ({ row, column }) => {
                      if (row[column["property"]] > row["receivableAmount"]) {
                        row["discountDetail"] = 0;
                        this.$message.error("优惠金额不能大于应收金额，请重新填写");
                      } else {
                        row["receivedAmount"] = Number(row["receivableAmount"]) - Number(row[column["property"]]);
                      }
                    }
                  }
                },
              },
              {
                field: "receivedAmount",
                title: "实收金额",
                minWidth: 100,
                editRender: {
                  name: "$input",
                  props: {
                    type: "float",
                    min: 0,
                    max: 99999,
                    digits: 2,
                    maxlength: 24,
                    placeholder: "请输入实收金额",
                  },
                },
              },
            ],
          },
        ],
        editRules: {
          discountDetail: [
            { required: true, message: "请输入优惠金额" },
            {
              validator: ({ cellValue, field, row }) => {
                if (cellValue > row.receivableAmount) {
                  row[field] = 0;
                  return new Error(`优惠金额不能大于应收金额，请重新填写!`);
                }
              },
            }
          ],
        },
        cellClassName: ({ row, column }) => {
          if (column.property === 'receivableAmount') {
            return 'blue'
          }
          if (column.property === 'discountDetail') {
            return 'red'
          }
          if (column.property === 'receivedAmount') {
            return 'green'
          }
          return null
        },
        editConfig: {
          trigger: "click",
          mode: "cell",
          autoClear: false,
          activeMethod({ row, rowIndex, column, columnIndex }) {
            if (columnIndex === 4) {
              return true;
            }
            return false;
          },
        },
        checkboxConfig: {
          showHeader: true,
          labelField: "feeMonth",
          trigger: "row",
        },
      },
    };
  },
  methods: {
    ...mapActions(["submitEventService"]),

    // 初始化方法
    init(name, code, room, selectRecords) {
      this.tableData = JSON.parse(selectRecords)
      this.$refs.xModal.open();
    },

    // 列表按钮组功能通用事件
    toolbarCustomEvent({ code, button }) {
      Object.keys(button.events).forEach((key, index) => {
        if (index == 0) button.events[key](button);
      });
    },

    async saveEvent() {
      const errMap = await this.$refs.xGrid.fullValidate(true).catch(errMap => errMap);
      if (errMap) {
        this.$message.error("请完善数据内容");
      } else {
        this.$refs.xGrid.clearActived().then(() => {
          this.submitData()
        })
      }
    },

    // 提交数据
    submitData() {
      if (this.loading) return;
      this.loading = true;
      this.submitEventService({
        service: "/fee/fPayFeeDetail/batchSaveOrUpdate",
        params: this.tableData,
      }).then(({ code }) => {
        if (code == 200) {
          this.$parent.$refs.xGrid.getLists();
          this.$message.success("保存成功");
          this.closeModal();
        }
        this.resetEvent();
      }).catch((err) => {
        this.resetEvent();
      });
    },

    resetEvent() {
      this.loading = false;
    },

    closeModal() {
      this.$refs.xModal.close();
    },
  },
};
</script>

<style lang="scss">
</style>
