<template>
<vxe-modal ref="xModal" :title="modalTitle" width="800" show-zoom>
  <template v-slot>
    <vxe-form ref="xForm" v-bind="xFormOptions" v-loading="loading" :data="xFormData" @submit="submitEvent" @reset="closeModal">
      <template #configItems>
        <div class="table">
          <VxeBasicTable ref="xGrid" service="/fee/fPayFee" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
          </VxeBasicTable>
        </div>
      </template>
    </vxe-form>
  </template>
</vxe-modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["roomInfo"],
  data() {
    const options = [];
    for (let i = 1; i <= 12; i++) {
      options.push({
        value: `${i}`,
        label: `${i}`
      })
    }
    return {
      loading: false,
      modalTitle: "",
      xFormOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "name",
            title: "房间编号",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                disabled: true,
                placeholder: "请选择房间编号",
              },
            },
          },
          {
            field: "cycle",
            title: "生成周期",
            span: 12,
            itemRender: {
              name: "$select",
              options: options,
              props: {
                placeholder: "请选择生成周期",
              },
            },
          },
          {
            field: 'configIds',
            title: '项目名称',
            span: 24,
            slots: { default: 'configItems' }
          },
          {
            field: 'remark',
            title: '摘要',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: '请输入摘要内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          cycle: [{ required: true, message: "请选择生成周期" }],
          startDate: [{ required: true, message: "请选择开始日期" }],
          endDate: [{ required: true, message: "请选择结束日期" }],
        },
        // 新增功能字段初始化
        data: {
          cycle: "3",
          feeFlag: "1",
          type: "house",
          ids: [],
          configIds: []
        },
      },
      xFormData: {},
      gridOptions: {
        height: 300,
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "bindStandards",
              name: "绑定标准",
              status: "primary",
              events: {
                click: () => {
                  this.$refs.xGrid.insertEvent();
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          zoom: false,
          custom: false,
        },
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: 'configName',
            title: '项目名称',
            minWidth: 160,
            editRender: {
              name: '$input',
              props: {
                maxlength: 24,
                placeholder: "请输入房屋名称"
              }
            }
          },
          {
            field: "startTime",
            title: "计费开始日",
            minWidth: 120,
            align: "center",
            editRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择费用类型"
              }
            },
          },
        ],
        pagerConfig: {
          // enabled: false,
          total: 10,
          currentPage: 1,
          pageSize: 10,
        },
        editConfig: {
          enabled: false,
        },
        checkboxConfig: {
          checkAll: false,
        },
        events: {
          'checkbox-change': ({ records }) => {
            this.xFormData.configIds = [];
            records.map(item => {
              this.xFormData.configIds.push(item.configId);
            });
          },
          'checkbox-all': ({ records }) => {
            this.xFormData.configIds = [];
            records.map(item => {
              this.xFormData.configIds.push(item.configId);
            });
          },
        },
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 110,
        items: [{
            field: "configId",
            title: "收费项",
            span: 24,
            itemRender: {
              name: "$VxeSelectList",
              service: "/fee/fPayFeeConfig",
              defaultProps: {
                label: "feeName",
                value: "id",
              },
              defaultParams: {
                parentId: "",
                feeFlag: "1",
                chargeType: "1",
              },
              props: {
                placeholder: "请选择收费项",
              },
            },
          },
          {
            field: "roomType",
            title: "房屋类型",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "houseType",
              props: {
                disabled: true,
                placeholder: "请选择房屋类型",
              },
            },
          },
          {
            field: "roomStatus",
            title: "房屋状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "houseStatus",
              props: {
                disabled: true,
                multiple: true,
                placeholder: "请选择房屋状态(可多选)",
              },
            },
          },
          {
            field: "startTime",
            title: "起始时间",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "month",
                labelFormat: "yyyy-MM",
                placeholder: "请选择起始时间",
              },
            },
          },
          {
            field: "endTime",
            title: "结束时间",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "month",
                labelFormat: "yyyy-MM",
                placeholder: "请选择起始时间",
              },
            },
          },
          {
            field: "payStatus",
            title: "缴费状态",
            span: 12,
            visible: false,
            itemRender: {
              name: "$select",
              optionTypeCode: "paymentStatus",
              props: {
                placeholder: "请选择缴费状态",
              },
            },
          },
          {
            field: 'remark',
            title: '描述',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: '请输入描述内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          parentId: [{ required: true, message: "请选择所属上级" }],
          configId: [{ required: true, message: "请选择收费项" }],
          roomType: [{ required: true, message: "请选择房屋类型" }],
          roomStatus: [
            { required: true, message: "请选择房屋状态" },
            {
              validator: ({ itemValue, rules, data }) => {
                if (itemValue && !itemValue.length) {
                  return new Error(`请选择房屋状态`);
                }
              },
            }
          ],
          startTime: [{ required: true, message: "请选择开始时间" }],
          endTime: [{ required: true, message: "请选择结束时间" }],
        },
        // 新增功能字段初始化
        data: {
          parentId: "",
          type: "",
          configId: '',
          roomType: "1",
          roomStatus: [],
          payerObjType: 'house',
          startTime: "",
          endTime: "",
          payStatus: "1",
        },
      },
      searchOptions: {
        items: [],
        data: {
          parentId: "",
        },
      },
    }
  },
  methods: {
    ...mapActions(['getSysDictService', 'submitEventService']),

    // 初始化方法
    init(name, code, room) {
      this.modalTitle = name;
      this.xFormOptions.data["ids"] = [room.id]
      this.xFormOptions.data["name"] = room.name;
      this.xFormOptions.data["communityId"] = room.communityId;
      this.xFormOptions.data["configIds"] = [];
      this.xFormData = { ...this.xFormOptions.data }

      // 设置表格查询条件
      this.searchOptions.data['parentId'] = room.id;
      this.searchOptions.data['payerObjType'] = "house";
      this.searchOptions.data['type'] = "1004";

      // 设置绑定收费标准的初始值
      let { items, data } = this.formOptions;
      data['parentId'] = room.id;
      data["roomType"] = room.roomType;
      data["roomStatus"] = [room.status];
      data["type"] = "1004";
      let { defaultParams } = items[0].itemRender
      defaultParams.parentId = room.communityId;
      defaultParams.feeTypeId = "";
      this.$refs.xModal.open();
    },

    // 生成周期性费用
    submitEvent({ data }) {
      if (this.loading) return;
      if (!data.configIds.length) {
        this.$message.error("请选择收费项");
        return;
      }
      this.$refs["xForm"].validate((valid) => {
        if (!valid) {
          this.loading = true;
          this.submitEventService({
            service: "/fee/generateFee/generateFee",
            params: data,
          }).then(({ code }) => {
            if (code == 200) {
              this.$parent.$refs.xGrid.getLists();
              this.$message.success("费用创建成功");
              this.closeModal();
            }
            this.resetEvent();
          }).catch((err) => {
            this.resetEvent();
          });
        }
      });
    },

    resetEvent() {
      this.loading = false;
    },

    closeModal() {
      this.$refs.xModal.close();
    },
  }
}
</script>

<style>
.table {
  height: 300px;
}
</style>
