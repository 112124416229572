<template>
<!-- 新增临时收费 -->
<vxe-modal ref="xModal" :title="modalTitle" width="800" show-zoom>
  <template v-slot>
    <vxe-form ref="xForm" v-bind="formOptions" v-loading="loading" :data="formData" @submit="submitEvent" @reset="closeModal"></vxe-form>
  </template>
</vxe-modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      modalTitle: "购水",
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "configId",
            title: "收费项",
            span: 24,
            itemRender: {
              name: "$VxeSelectList",
              service: "/fee/fPayFeeConfig",
              defaultProps: {
                label: "feeName",
                value: "id",
              },
              defaultParams: {
                parentId: "",
                feeFlag: "",
                feeTypeId: "",
              },
              props: {
                placeholder: "请选择收费项",
              },
              events: {
                click: (params, node) => {
                  let { data, property } = params;
                  data[property] = node.id;
                  data["computingFormula"] = node.computingFormula;
                  if (data.computingFormula == 1) {
                    data["squarePrice"] = node.squarePrice;
                    data["fixedPrice"] = "";
                    data["computingFormulaText"] = ""
                    data["count"] = "";
                    data["amount"] = this.calcEvent(data["squarePrice"], data["count"]);
                  } else if (data.computingFormula == 2) {
                    data["squarePrice"] = "";
                    data["fixedPrice"] = node.fixedPrice;
                    data["computingFormulaText"] = ""
                    data["count"] = "";
                  } else if (data.computingFormula == 100) {
                    data["squarePrice"] = "";
                    data["fixedPrice"] = "";
                    data["computingFormulaText"] = node.computingFormulaText;
                    data["count"] = "";
                  }
                }
              }
            },
          },
          {
            field: "computingFormula",
            title: "计算公式",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "computingFormula",
              props: {
                disabled: true,
                placeholder: "请选择计算公式",
              },
            },
          },
          {
            field: "squarePrice",
            title: "单价",
            visibleMethod({ data }) {
              if (data.computingFormula == 1) {
                return true
              } else {
                return false
              }
            },
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                disabled: true,
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入单价",
              },
            },
          },
          {
            field: "fixedPrice",
            title: "固定费用",
            visibleMethod({ $form, data }) {
              if (data.computingFormula == 2) {
                return true;
              } else {
                return false
              }
            },
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                disabled: true,
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入固定费用",
              },
            },
          },
          {
            field: "computingFormulaText",
            title: "计算参数",
            span: 12,
            visibleMethod({ $form, data, property }) {
              let { rules } = $form;
              if (data.computingFormula == '100') {
                rules[property][0].required = true;
                return true;
              } else {
                rules[property][0].required = false;
                return false;
              }
            },
            itemRender: {
              name: "$select",
              optionTypeCode: "computingFormulaText",
              props: {
                disabled: true,
                placeholder: "请选择计算参数",
              },
            },
          },
          {
            field: "count",
            title: "购买数量",
            span: 12,
            visibleMethod({ data }) {
              if (data.computingFormula != 2) {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 9,
                placeholder: "请输入购买数量",
              },
              events: {
                input: ({ data, property }) => {
                  if (data.computingFormula == 1) {
                    data["amount"] = this.calcEvent(data["squarePrice"], data["count"]);
                  }
                }
              }
            },
          },
          {
            field: "amount",
            title: "金额",
            span: 12,
            visibleMethod({ data }) {
              if (data.computingFormula == '1') {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                maxlength: 9,
                placeholder: "请输入购买金额",
              },
            },
          },
          {
            field: "startDate",
            title: "开始日期",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择开始日期",
              }
            },
          },
          {
            field: "endDate",
            title: "结束日期",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择结束日期",
              }
            },
          },
          {
            field: 'remark',
            title: '摘要',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: '请输入摘要内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          configId: [{ required: true, message: "请选择收费项" }],
          computingFormula: [{ required: true, message: "请选择计算公式" }],
          squarePrice: [{ required: true, message: "请输入单价" },
            {
              validator: ({ itemValue, rules, data }) => {
                let { computingFormula } = data;
                if (computingFormula != 1) {
                  rules[0].required = false;
                }
                if (computingFormula == 1 && !itemValue) {
                  rules[0].required = true;
                  return new Error(`请输入单价`);
                }
              },
            }
          ],
          count: [
            { required: true, message: "请输入购买数量" },
            {
              validator: ({ itemValue, rules, data }) => {
                let { computingFormula } = data;
                if (computingFormula != 1 && computingFormula != 100) {
                  rules[0].required = false;
                }
                if ((computingFormula == 1 || computingFormula == 100) && !itemValue) {
                  rules[0].required = true;
                  return new Error(`请输入购买数量`);
                }
              },
            }
          ],
          computingFormulaText: [
            { required: true, message: "请选择计算参数" },
            {
              validator: ({ itemValue, rules, data }) => {
                let { computingFormula } = data;
                if (computingFormula != 100) {
                  rules[0].required = false;
                }
                if (computingFormula == 100 && !itemValue) {
                  rules[0].required = true;
                  return new Error(`请选择计算参数`);
                }
              },
            }
          ],
          amount: [{ required: true, message: "请输入购买金额" },
            {
              validator: ({ itemValue, rules, data }) => {
                let { computingFormula } = data;
                if (computingFormula != 1) {
                  rules[0].required = false;
                }
                if (computingFormula == 1 && !itemValue) {
                  rules[0].required = true;
                  return new Error(`请输入购买金额`);
                }
              },
            }
          ],
          startDate: [{ required: true, message: "请选择开始日期" }],
          endDate: [{ required: true, message: "请选择结束日期" }],
        },
        // 新增功能字段初始化
        data: {
          parentId: "",
          subId: "",
          configId: "",
          type: "house",
          computingFormula: "",
          computingFormulaText: "",
          count: "",
          amount: "",
          startDate: "",
          endDate: "",
        },
      },
      formData: {},
    }
  },
  methods: {
    ...mapActions(['getSysDictService', 'submitEventService']),

    // 获取数据字典
    getSysDictEvent() {
      this.getSysDictService({
          params: ["computingFormula"]
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.formOptions.items = [...this.$Tools.buildDataByDicts(this.formOptions.items, data, { value: "code", label: "name" })]
          }
        });
    },

    // 初始化方法
    init(name, code, currNode) {
      this.getSysDictEvent();
      this.modalTitle = name;
      let { items, data } = this.formOptions;
      data.parentId = currNode.id;
      data.subId = currNode.id;
      data.startDate = this.$XEUtils.toDateString(new Date(), "yyyy-MM-dd HH:mm:ss");
      data.endDate = this.$XEUtils.toDateString(new Date(), "yyyy-MM-dd HH:mm:ss");

      let { defaultParams } = items[0].itemRender;
      if (code == "buyWater") {
        defaultParams.parentId = currNode.communityId || currNode.id;
        defaultParams.feeFlag = 3; // 费用标识
        defaultParams.feeTypeId = 6; // 费用类型
      }
      if (code == "buyPower") {
        defaultParams.parentId = currNode.communityId || currNode.id;
        defaultParams.feeFlag = 3;
        defaultParams.feeTypeId = 7;
      }
      if (code == "tempCost") {
        defaultParams.parentId = currNode.communityId || currNode.id;
        defaultParams.feeFlag = 10;
        defaultParams.feeTypeId = "";
      }
      this.formData = { ...data };
      this.$refs.xModal.open();
    },

    // 计算金额
    calcEvent(price, count) {
      return this.$XEUtils.commafy(this.$XEUtils.multiply(price, count), { digits: 2 });
    },

    // 创建临时费用
    submitEvent({ data }) {
      if (this.loading) return;
      this.$refs["xForm"].validate((valid) => {
        if (!valid) {
          this.loading = true;
          this.submitEventService({
            service: "/fee/generateFee/generateFee",
            params: data,
          }).then(({ code }) => {
            if (code == 200) {
              this.$parent.$refs.xGrid.getLists();
              this.$message.success("费用创建成功");
              this.closeModal();
            }
            this.resetEvent();
          }).catch((err) => {
            this.resetEvent();
          });
        }
      });
    },

    resetEvent() {
      this.loading = false;
    },

    closeModal() {
      this.$refs.xModal.close();
    },
  }
}
</script>

<style>

</style>
